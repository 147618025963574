import React, { useState } from 'react';
import '../../styles/components/agentForms/JmeterTestForm.css';
import { RxCross1 } from 'react-icons/rx';
import { postApiCall, getApiCall } from '../../app/apiCalls';
import { pollStatus } from '../../utils/getStatus';

const JmeterTestForm = ({ selectedAgent, setShowResult, setResultData }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    sourceType: 'file',
    // Repo details
    repoUrl: '',
    gitUsername: '',
    gitPassword: '',
    branchName: '',
    // Other automation details
    s3_uri: '',
    additionalParams: '',
    prescript: ''
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [isGeneratingResult, setIsGeneratingResult] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState({
    automationFile: null
  });
  const [uploadedFiles, setUploadedFiles] = useState({
    automationFile: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileSelect = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setSelectedFiles(prev => ({
        ...prev,
        [name]: files[0]
      }));
    }
  };

  const uploadFileToS3 = async (file, fileType) => {
    try {
      const response = await postApiCall(`/upload-files/jmx`);
      
      const formData = new FormData();
      formData.append('key', response.fields.key);
      formData.append('AWSAccessKeyId', response.fields.AWSAccessKeyId);
      formData.append('policy', response.fields.policy);
      formData.append('signature', response.fields.signature);
      formData.append('x-amz-security-token', response.fields['x-amz-security-token']);
      formData.append('file', file);

      const uploadResponse = await fetch(response.url, {
        method: 'POST',
        body: formData,
        redirect: 'follow'
      });

      if (!uploadResponse.ok && uploadResponse.status !== 204) {
        throw new Error('Failed to upload file to S3');
      }

      return response.s3_uri;
    } catch (error) {
      console.error('File upload failed:', error);
      throw error;
    }
  };

  const handleUpload = async (fileType) => {
    if (!selectedFiles[fileType]) return;

    try {
      const s3Uri = await uploadFileToS3(selectedFiles[fileType], fileType);
      setUploadedFiles(prev => ({
        ...prev,
        [fileType]: s3Uri
      }));
      if (fileType === 'automationFile') {
        setFormData(prev => ({
          ...prev,
          s3_uri: s3Uri
        }));
      }
    } catch (error) {
      setErrorMessage('Failed to upload file. Please try again.');
    }
  };

  const validateForm = () => {
    if (!formData.name.trim()) {
      return "Test name is required";
    }

    if (formData.sourceType === 'repo') {
      if (!formData.repoUrl.trim()) {
        return "Repository URL is required";
      }
      if (!isValidUrl(formData.repoUrl)) {
        return "Please enter a valid repository URL";
      }
      if (!formData.gitUsername.trim()) {
        return "Git username is required";
      }
      if (!formData.gitPassword.trim()) {
        return "Git password is required";
      }
      if (!formData.branchName.trim()) {
        return "Branch name is required";
      }
    } else {
      if (!formData.s3_uri) {
        return "Please upload the automation file";
      }
    }

    return "";
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const fetchTestResults = async (testrun_id) => {
    try {
      const response = await getApiCall(`/jmeter-test-results/${testrun_id}`);
      if (response?.status_code === 200) {
        // Set the entire response data structure
        setResultData({
          data: {
            test_summary_data: response.test_summary_data,
            test_graph_data: response.test_graph_data
          }
        });
        setShowResult(true);
      } else {
        throw new Error("Failed to fetch test results");
      }
    } catch (error) {
      console.error("Error fetching test results:", error);
      setErrorMessage("Failed to fetch test results. Please try again later.");
    } finally {
      setIsGeneratingResult(false);
    }
  };

  const handleGenerate = (e) => {
    e.preventDefault();
    setShowResult(false);
    setErrorMessage("");
    setResultData(undefined);

    const validationError = validateForm();
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    let testrun_id = "";
    setIsGeneratingResult(true);

    const formDataToSend = new FormData();
    formDataToSend.append('test_name', formData.name);
    formDataToSend.append('test_description', formData.description);
      
    if (formData.sourceType === 'repo') {
      formDataToSend.append('repo_url', formData.repoUrl);
      formDataToSend.append('git_username', formData.gitUsername);
      formDataToSend.append('git_password', formData.gitPassword);
      formDataToSend.append('branch_name', formData.branchName);
    }
    if (formData.s3_uri) {
      formDataToSend.append('s3_uri', formData.s3_uri);
    }
    if (formData.additionalParams) {
      formDataToSend.append('additional_params', formData.additionalParams);
    }
    if (formData.prescript) {
      formDataToSend.append('prescript', formData.prescript);
    }

    postApiCall(`jmeter-test`, formDataToSend)
      .then((res) => {
        if (res?.status_code === 200) {
          testrun_id = res?.data?.load_test_details?.testrun_id;
          return pollStatus(testrun_id, selectedAgent?.id, res?.data);
        } else {
          throw new Error("Failed to initiate JMeter test");
        }
      })
      .then((res) => {
        if (res?.status_code === 200 && res?.data?.testrun_status === "completed") {
          return fetchTestResults(testrun_id);
        } else {
          throw new Error("Test run did not complete successfully");
        }
      })
      .catch((err) => {
        console.error(err);
        setIsGeneratingResult(false);
        setErrorMessage("Something went wrong. Please try again later");
      });
  };

  const handleReset = () => {
    setFormData({
      name: '',
      description: '',
      sourceType: 'repo',
      repoUrl: '',
      gitUsername: '',
      gitPassword: '',
      branchName: '',
      s3_uri: '',
      additionalParams: '',
      prescript: ''
    });
    setSelectedFiles({ automationFile: null });
    setUploadedFiles({ automationFile: '' });
    setErrorMessage('');
  };

  const getInputClassName = (fieldName) => {
    if (errorMessage && !formData[fieldName]) {
      return 'form-input error';
    }
    return 'form-input';
  };

  const renderRightSideContent = () => {
    return (
      <div className="form-section">
        <div className="form-group source-type">
          <label>Source Type<span className="required">*</span></label>
          <select
            name="sourceType"
            value={formData.sourceType}
            onChange={handleInputChange}
            className={getInputClassName('sourceType')}
          >
            <option value="repo">Repository Details</option>
            <option value="file">File Upload</option>
          </select>
        </div>
        {formData.sourceType === 'repo' ? (
          <div className="repo-fields">
            <div className="form-group">
              <label>Repository URL<span className="required">*</span></label>
              <input
                type="text"
                name="repoUrl"
                className={getInputClassName('repoUrl')}
                value={formData.repoUrl}
                onChange={handleInputChange}
                placeholder="Enter repository URL"
              />
            </div>

            <div className="form-group">
              <label>Git Username<span className="required">*</span></label>
              <input
                type="text"
                name="gitUsername"
                className={getInputClassName('gitUsername')}
                value={formData.gitUsername}
                onChange={handleInputChange}
                placeholder="Enter Git username"
              />
            </div>

            <div className="form-group">
              <label>Git Password<span className="required">*</span></label>
              <input
                type="password"
                name="gitPassword"
                className={getInputClassName('gitPassword')}
                value={formData.gitPassword}
                onChange={handleInputChange}
                placeholder="Enter Git password"
              />
            </div>

            <div className="form-group">
              <label>Branch Name<span className="required">*</span></label>
              <input
                type="text"
                name="branchName"
                className={getInputClassName('branchName')}
                value={formData.branchName}
                onChange={handleInputChange}
                placeholder="Enter branch name"
              />
            </div>
          </div>
        ) : (
          <div className="form-group">
            <label>Upload Automation File<span className="required">*</span></label>
            <div className="file-upload-container">
              <div className="file-input-wrapper">
                <input
                  type="file"
                  name="automationFile"
                  onChange={handleFileSelect}
                  accept=".jmx, .zip"
                  className={getInputClassName('automationFile')}
                />
              </div>
              <button
                type="button"
                className="upload-button"
                onClick={() => handleUpload('automationFile')}
                disabled={!selectedFiles.automationFile || uploadedFiles.automationFile}
              >
                {uploadedFiles.automationFile ? 'Uploaded' : 'Upload'}
              </button>
            </div>
          </div>
        )}

        <div className="form-group mt-4">
          <label>Additional Parameters</label>
          <input
            type="text"
            name="additionalParams"
            value={formData.additionalParams}
            onChange={handleInputChange}
            placeholder="Enter additional parameters"
          />
        </div>

        <div className="form-group">
          <label>Prescript</label>
          <textarea
            name="prescript"
            value={formData.prescript}
            onChange={handleInputChange}
            placeholder="Enter prescript"
            rows="3"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <form className="test-api-form">
        <div className="form-container">
          {/* Basic Details - Left Side */}
          <div className="basic-details-section">
            <div className="form-section">
              <div className="form-group">
                <label>Name<span className="required">*</span></label>
                <input
                  type="text"
                  name="name"
                  className={getInputClassName('name')}
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Enter name"
                />
              </div>

              <div className="form-group">
                <label>Description</label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder="Enter description"
                  rows="3"
                />
              </div>
            </div>
          </div>

          {/* Conditional Form Fields - Right Side */}
          <div className="conditional-fields-section">
            {renderRightSideContent()}
          </div>
        </div>
      </form>

      <div className="form-button-wrapper">
        <button className="reset-button" onClick={handleReset}>
          <RxCross1 /> Reset
        </button>
        <button 
          className="test-button" 
          disabled={isGeneratingResult} 
          onClick={handleGenerate}
        >
          Test
        </button>
      </div>

      {errorMessage && (
        <p className="error-message">{errorMessage}</p>
      )}
      
      {isGeneratingResult && (
        <div className='d-flex flex-row w-100 justify-content-start align-items-center mt-4'>
          <div className='d-flex flex-row justify-content-center align-items-center'>
            <progress id="progress-bar" aria-label="Content loading…"></progress>
          </div>
          <p className='wait-text'>I am working on it, thanks for waiting (~3mins)</p>
        </div>
      )}
    </>
  );
};

export default JmeterTestForm; 