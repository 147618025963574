import React from 'react';
import ReactECharts from 'echarts-for-react';
import '../../styles/components/agentResults/JmeterResult.css';
import { formatCurrentDateTime } from '../../utils/formatCurrentDateTime';

const JmeterResult = ({ resultData }) => {
  if (!resultData?.data) return null;

  const { test_summary_data, test_graph_data } = resultData.data;

  // Response Time & Active Users vs Time
  const responseTimeOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'cross' }
    },
    legend: {
      data: ['Response Time', 'Active Users']
    },
    xAxis: {
      type: 'category',
      data: test_graph_data.map(d => d.time_interval),
      name: 'Time (s)',
      nameLocation: 'middle',
      nameGap: 35
    },
    yAxis: [
      {
        type: 'value',
        name: 'Response Time (ms)',
        position: 'left'
      },
      {
        type: 'value',
        name: 'Active Users',
        position: 'right'
      }
    ],
    series: [
      {
        name: 'Response Time',
        type: 'line',
        areaStyle: {},
        data: test_graph_data.map(d => d.response_time),
        yAxisIndex: 0,
        itemStyle: { color: '#20264D' }
      },
      {
        name: 'Active Users',
        type: 'line',
        data: test_graph_data.map(d => d.active_users),
        yAxisIndex: 1,
        itemStyle: { color: '#28a745' }
      }
    ]
  };

  // Latency & Active Users vs Time
  const latencyOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'cross' }
    },
    legend: {
      data: ['Latency', 'Active Users']
    },
    xAxis: {
      type: 'category',
      data: test_graph_data.map(d => d.time_interval),
      name: 'Time (s)',
      nameLocation: 'middle',
      nameGap: 35
    },
    yAxis: [
      {
        type: 'value',
        name: 'Latency (ms)',
        position: 'left'
      },
      {
        type: 'value',
        name: 'Active Users',
        position: 'right'
      }
    ],
    series: [
      {
        name: 'Latency',
        type: 'line',
        areaStyle: {},
        data: test_graph_data.map(d => d.latency),
        yAxisIndex: 0,
        itemStyle: { color: '#f78181' }
      },
      {
        name: 'Active Users',
        type: 'line',
        data: test_graph_data.map(d => d.active_users),
        yAxisIndex: 1,
        itemStyle: { color: '#28a745' }
      }
    ]
  };

  // Error Rate & Active Users vs Time
  const errorRateOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'cross' }
    },
    legend: {
      data: ['Error Rate', 'Active Users']
    },
    xAxis: {
      type: 'category',
      data: test_graph_data.map(d => d.time_interval),
      name: 'Time (s)',
      nameLocation: 'middle',
      nameGap: 35
    },
    yAxis: [
      {
        type: 'value',
        name: 'Error Rate (%)',
        position: 'left'
      },
      {
        type: 'value',
        name: 'Active Users',
        position: 'right'
      }
    ],
    series: [
      {
        name: 'Error Rate',
        type: 'line',
        areaStyle: {},
        data: test_graph_data.map(d => d.error_rate),
        yAxisIndex: 0,
        itemStyle: { color: '#dc3545' }
      },
      {
        name: 'Active Users',
        type: 'line',
        data: test_graph_data.map(d => d.active_users),
        yAxisIndex: 1,
        itemStyle: { color: '#28a745' }
      }
    ]
  };

  // Throughput & Active Users vs Time
  const throughputOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'cross' }
    },
    legend: {
      data: ['Throughput', 'Active Users']
    },
    xAxis: {
      type: 'category',
      data: test_graph_data.map(d => d.time_interval),
      name: 'Time (s)',
      nameLocation: 'middle',
      nameGap: 35
    },
    yAxis: [
      {
        type: 'value',
        name: 'Throughput (req/s)',
        position: 'left'
      },
      {
        type: 'value',
        name: 'Active Users',
        position: 'right'
      }
    ],
    series: [
      {
        name: 'Throughput',
        type: 'line',
        areaStyle: {},
        data: test_graph_data.map(d => d.throughput),
        yAxisIndex: 0,
        itemStyle: { color: '#20264D' }
      },
      {
        name: 'Active Users',
        type: 'line',
        data: test_graph_data.map(d => d.active_users),
        yAxisIndex: 1,
        itemStyle: { color: '#28a745' }
      }
    ]
  };

  // Response Time & Latency Range
  const timeRangeOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' }
    },
    legend: {
      data: ['Response Time', 'Latency']
    },
    xAxis: {
      type: 'category',
      data: ['0-1s', '1-2s', '2-5s', '5-10s', '>10s'],
      name: 'Time Range',
      nameLocation: 'middle',
      nameGap: 35
    },
    yAxis: {
      type: 'value',
      name: 'Count',
      nameLocation: 'middle',
      nameGap: 40
    },
    series: [
      {
        name: 'Response Time',
        type: 'bar',
        data: [
          test_graph_data.filter(d => d.response_time < 1000).length,
          test_graph_data.filter(d => d.response_time >= 1000 && d.response_time < 2000).length,
          test_graph_data.filter(d => d.response_time >= 2000 && d.response_time < 5000).length,
          test_graph_data.filter(d => d.response_time >= 5000 && d.response_time < 10000).length,
          test_graph_data.filter(d => d.response_time >= 10000).length
        ],
        itemStyle: { color: '#20264D' }
      },
      {
        name: 'Latency',
        type: 'bar',
        data: [
          test_graph_data.filter(d => d.latency < 1000).length,
          test_graph_data.filter(d => d.latency >= 1000 && d.latency < 2000).length,
          test_graph_data.filter(d => d.latency >= 2000 && d.latency < 5000).length,
          test_graph_data.filter(d => d.latency >= 5000 && d.latency < 10000).length,
          test_graph_data.filter(d => d.latency >= 10000).length
        ],
        itemStyle: { color: '#f78181' }
      }
    ]
  };

  // Success vs Error Pie Chart
  const successErrorOptions = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'horizontal',
      bottom: 'bottom'
    },
    series: [{
      name: 'Test Results',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '20',
          fontWeight: 'bold'
        }
      },
      data: [
        {
          value: test_summary_data.total_success_count,
          name: 'Success',
          itemStyle: { color: '#28a745' }
        },
        {
          value: test_summary_data.total_error_count,
          name: 'Error',
          itemStyle: { color: '#dc3545' }
        }
      ]
    }]
  };

  return (
    <div className='jmeter-result'>
      <div className='jmeter-result-header'>
        <p>The JMeter test was successfully completed at {formatCurrentDateTime()}.</p>
      </div>

      <div className='jmeter-stats-grid'>
        
        <div className='stat-card'>
          <h3>{test_summary_data.total_users}</h3>
          <p>Total Users</p>
        </div>
        
        <div className='stat-card success'>
          <h3>{test_summary_data.total_success_count}</h3>
          <p>Success Count</p>
        </div>
        
        <div className='stat-card error'>
          <h3>{test_summary_data.total_error_count}</h3>
          <p>Error Count</p>
        </div>
        
        <div className='stat-card'>
          <h3>{test_summary_data.avg_response_time.toFixed(1)}ms</h3>
          <p>Avg Response Time</p>
        </div>

        <div className='stat-card'>
          <h3>{test_summary_data.avg_throughput.toFixed(2)}</h3>
          <p>Avg Throughput (req/s)</p>
        </div>
      </div>

      <div className='jmeter-charts'>
        <div className='chart-container'>
          <h4>Response Time & Active Users vs Time</h4>
          <ReactECharts option={responseTimeOptions} style={{ height: '400px' }} />
        </div>
        
        <div className='chart-container'>
          <h4>Latency & Active Users vs Time</h4>
          <ReactECharts option={latencyOptions} style={{ height: '400px' }} />
        </div>

        <div className='chart-container'>
          <h4>Error Rate & Active Users vs Time</h4>
          <ReactECharts option={errorRateOptions} style={{ height: '400px' }} />
        </div>

        <div className='chart-container'>
          <h4>Throughput & Active Users vs Time</h4>
          <ReactECharts option={throughputOptions} style={{ height: '400px' }} />
        </div>

        <div className='chart-container'>
          <h4>Response Time & Latency Distribution</h4>
          <ReactECharts option={timeRangeOptions} style={{ height: '400px' }} />
        </div>

        <div className='chart-container'>
          <h4>Success vs Error Distribution</h4>
          <ReactECharts option={successErrorOptions} style={{ height: '400px' }} />
        </div>
      </div>
    </div>
  );
};

export default JmeterResult; 