import React, { useState } from 'react';
import '../../styles/components/agentForms/WebappTestForm.css';
import { RxCross1 } from 'react-icons/rx';
import { postApiCall } from '../../app/apiCalls';
import { pollStatus } from '../../utils/getStatus';

const WebappTestForm = ({ selectedAgent, testingFramework, testingLanguage, setShowResult, setResultData }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    sourceType: 'repo',
    aut_url: '',
    // Repo details
    repoUrl: '',
    gitUsername: '',
    gitPassword: '',
    branchName: '',
    testing_framework: testingFramework || "selenium",
    testing_language: testingLanguage || "java",
    // Other automation details
    s3_uri: '',
    testRunCommand: '',
    reportFileLocation: '',
    is_upa: false,
    status: "",
    browsers: {
      chrome: {
        latest: false,
        'latest-1': false,
        'latest-n': false
      },
      firefox: {
        latest: false,
        'latest-1': false,
        'latest-n': false
      },
      edge: {
        latest: false
      }
    }
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [isGeneratingResult, setIsGeneratingResult] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState({
    collectionFile: null,
    automationFile: null
  });

  // Add new state for tracking uploaded files
  const [uploadedFiles, setUploadedFiles] = useState({
    automationFile: null
  });

  // Add new state for tracking upload loading state
  const [isUploading, setIsUploading] = useState({
    automationFile: false
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const uploadFileToS3 = async (file, fileType) => {
    try {
      // Step 1: Get presigned URL
      const response = await postApiCall(`/upload-files/${fileType}`);
      console.log(response);

      // Step 2: Create form data for S3 upload
      const formData = new FormData();
      
      // Append only the necessary fields in the correct order
      formData.append('key', response.fields.key);
      formData.append('AWSAccessKeyId', response.fields.AWSAccessKeyId);
      formData.append('policy', response.fields.policy);
      formData.append('signature', response.fields.signature);
      formData.append('x-amz-security-token', response.fields['x-amz-security-token']);
      formData.append('file', file);

      // Step 3: Upload to S3 using fetch
      const uploadResponse = await fetch(response.url, {
        method: 'POST',
        body: formData,
        redirect: 'follow'
      });

      if (!uploadResponse.ok && uploadResponse.status !== 204) {
        throw new Error('Failed to upload file to S3');
      }

      return response.s3_uri;
    } catch (error) {
      console.error('File upload failed:', error);
      throw error;
    }
  };

  const handleFileSelect = (e) => {
    const { name, files } = e.target;
    if (!files || !files[0]) return;

    setSelectedFiles(prev => ({
      ...prev,
      [name]: files[0]
    }));
    // Reset uploaded state when new file is selected
    setUploadedFiles(prev => ({
      ...prev,
      [name]: null
    }));
  };

  const handleUpload = async (fieldName) => {
    const file = selectedFiles[fieldName];
    if (!file) {
      setErrorMessage('Please select a file first');
      return;
    }

    // Set uploading state to true
    setIsUploading(prev => ({
      ...prev,
      [fieldName]: true
    }));

    try {
      const s3Uri = await uploadFileToS3(file, 'zip');
      
      setUploadedFiles(prev => ({
        ...prev,
        [fieldName]: s3Uri
      }));

      setFormData(prev => ({
        ...prev,
        s3_uri: s3Uri
      }));
    } catch (error) {
      setErrorMessage(`Failed to upload ${fieldName}. Please try again.`);
    } finally {
      // Reset uploading state regardless of success/failure
      setIsUploading(prev => ({
        ...prev,
        [fieldName]: false
      }));
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormData({
      name: '',
      description: '',
      sourceType: 'repo',
      repoUrl: '',
      gitUsername: '',
      gitPassword: '',
      branchName: '',
      collectionType: 'json',
      collectionFile: null,
      collectionFilename: '',
      envVariables: '',
      automationFile: null,
      testRunCommand: '',
      reportFileLocation: '',
      browsers: {
        chrome: {
          latest: false,
          'latest-1': false,
          'latest-n': false
        },
        firefox: {
          latest: false,
          'latest-1': false,
          'latest-n': false
        },
        edge: {
          latest: false
        }
      }
    });
    setErrorMessage("");
    setShowResult(false);
    setResultData([]);
  };

  const validateForm = () => {
    // Check name first
    if (!formData.name.trim()) {
      return "Test name is required";
    }

    if (formData.sourceType === 'repo') {
      // Repository validations in order of importance
      if (!formData.repoUrl.trim()) {
        return "Repository URL is required";
      }
      if (!isValidUrl(formData.repoUrl)) {
        return "Please enter a valid repository URL";
      }
      if (!formData.gitUsername.trim()) {
        return "Git username is required";
      }
      if (!formData.gitPassword.trim()) {
        return "Git password is required";
      }
      if (!formData.branchName.trim()) {
        return "Branch name is required";
      }
      if (!formData.testRunCommand.trim()) {
        return "Test run command is required";
      }
      if (!formData.reportFileLocation.trim()) {
        return "Report file location is required";
      }
    } else {
      // Other automation type validations (karate, rest-assured, robot)
      if(formData.s3_uri.trim().length === 0) {
        return "Automation file is required";
      }
    }

    return ""; // Return empty string if no errors
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const formatBrowsersForPayload = (browsers) => {
    const formattedBrowsers = [];
    
    // Process each browser type
    Object.entries(browsers).forEach(([browserName, versions]) => {
      // Get all selected versions (where value is true)
      const selectedVersions = Object.entries(versions)
        .filter(([_, isSelected]) => isSelected)
        .map(([version]) => version);
      
      // Only add browser if it has selected versions
      if (selectedVersions.length > 0) {
        formattedBrowsers.push({
          browser_name: browserName,
          browser_version: selectedVersions
        });
      }
    });
    
    return formattedBrowsers;
  };

  const handleGenerate = (e) => {
    e.preventDefault();
    setShowResult(false);
    setErrorMessage("");
    setResultData(undefined);

    const validationError = validateForm();
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    let testrun_id = "";
    setIsGeneratingResult(true);

    // Create payload based on form type
    const formDataToSend = new FormData();
    formDataToSend.append('test_name', formData.name);
    formDataToSend.append('test_description', formData.description);
    formDataToSend.append('test_type', 'functional_test');
    formDataToSend.append('aut_url', formData.aut_url);
    formDataToSend.append('testing_framework', formData.testing_framework);
    formDataToSend.append('testing_language', formData.testing_language);
      
    if (formData.sourceType === 'repo') {
      formDataToSend.append('repo_url', formData.repoUrl);
      formDataToSend.append('git_username', formData.gitUsername);
      formDataToSend.append('git_password', formData.gitPassword);
      formDataToSend.append('branch_name', formData.branchName);
      formDataToSend.append('env_variables', formData.envVariables || "");
    }
    if (formData.s3_uri) {
      formDataToSend.append('s3_uri', formData.s3_uri);
    }
    formDataToSend.append('test_run_command', formData.testRunCommand);
    formDataToSend.append('report_file_location', formData.reportFileLocation);

    // Format and append browsers data
    const formattedBrowsers = formatBrowsersForPayload(formData.browsers);
    formDataToSend.append('browsers_selected', JSON.stringify(formattedBrowsers));

    postApiCall(`multibrowser-test`, formDataToSend)
      .then((res) => {
        if (res?.status_code === 200) {
          testrun_id = res?.data?.load_test_details?.testrun_id;
          return pollStatus(testrun_id, selectedAgent?.id, res?.data);
        } else {
          throw new Error("Failed to initiate API test");
        }
      })
      .then((res) => {
        if (res?.status_code === 200 && res?.data?.testrun_status === "completed") {
          setResultData(res?.data);
          setShowResult(true);
          setIsGeneratingResult(false);
        } else {
          throw new Error("Test run did not complete successfully");
        }
      })
      .catch((err) => {
        console.error(err);
        setIsGeneratingResult(false);
        setErrorMessage("Something went wrong. Please try again later");
      });
  };

  // Update input fields to show validation state
  const getInputClassName = (fieldName) => {
    if (errorMessage && !formData[fieldName]) {
      return 'form-input error';
    }
    return 'form-input';
  };

  const renderRightSideContent = () => {
    return (
      <div className="form-section">
        <div className="form-group source-type">
          <label>Source Type<span className="required">*</span></label>
          <select
            name="sourceType"
            value={formData.sourceType}
            onChange={handleInputChange}
            className={getInputClassName('sourceType')}
          >
            <option value="repo">Repository Details</option>
            <option value="automation_code">Automation Code</option>
          </select>
        </div>

        {formData.sourceType === 'repo' ? (
          <div className="form-section">

          <div className="repo-fields">
            <div className="form-group">
              <label>Repository URL<span className="required">*</span></label>
              <input
                type="text"
                name="repoUrl"
                className={getInputClassName('repoUrl')}
                value={formData.repoUrl}
                onChange={handleInputChange}
                placeholder="Enter repository URL"
              />
            </div>

            <div className="form-group">
              <label>Git Username<span className="required">*</span></label>
              <input
                type="text"
                name="gitUsername"
                className={getInputClassName('gitUsername')}
                value={formData.gitUsername}
                onChange={handleInputChange}
                placeholder="Enter Git username"
              />
            </div>

            <div className="form-group">
              <label>Git Password<span className="required">*</span></label>
              <input
                type="password"
                name="gitPassword"
                className={getInputClassName('gitPassword')}
                value={formData.gitPassword}
                onChange={handleInputChange}
                placeholder="Enter Git password"
              />
            </div>

            <div className="form-group">
              <label>Branch Name<span className="required">*</span></label>
              <input
                type="text"
                name="branchName"
                className={getInputClassName('branchName')}
                value={formData.branchName}
                onChange={handleInputChange}
                placeholder="Enter branch name"
              />
            </div>
          </div>
          </div>
        ) : (
          <div className="form-section">
          <div className="form-group">
            <label>Upload Automation File<span className="required">*</span></label>
            <div className="file-upload-container">
              <div className="file-input-wrapper">
                <input
                  type="file"
                  name="automationFile"
                  onChange={handleFileSelect}
                  accept=".java,.py,.robot,.feature,.zip"
                  className={getInputClassName('automationFile')}
                />
              </div>
              <button
                type="button"
                className="upload-button"
                onClick={() => handleUpload('automationFile')}
                disabled={!selectedFiles.automationFile || uploadedFiles.automationFile || isUploading.automationFile}
              >
                <div className="upload-button-content">
                  {uploadedFiles.automationFile ? (
                    'Uploaded'
                  ) : isUploading.automationFile ? (
                    <>
                      <span className="loader"></span>
                      Uploading...
                    </>
                  ) : (
                    'Upload'
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
        )}
        <div className="form-group mt-4">
          <label>Test Run Command<span className="required">*</span></label>
          <input
            type="text"
            name="testRunCommand"
            className={getInputClassName('testRunCommand')}
            value={formData.testRunCommand}
            onChange={handleInputChange}
            placeholder="Enter test run command"
          />
        </div>

        <div className="form-group">
          <label>Report File Location<span className="required">*</span></label>
          <input
            type="text"
            name="reportFileLocation"
            className={getInputClassName('reportFileLocation')}
            value={formData.reportFileLocation}
            onChange={handleInputChange}
            placeholder="Enter report file location"
          />
        </div>
      </div>
    );
  };

  const handleBrowserChange = (browser, type) => {
    setFormData(prev => ({
      ...prev,
      browsers: {
        ...prev.browsers,
        [browser]: {
          ...prev.browsers[browser],
          [type]: !prev.browsers[browser][type]
        }
      }
    }));
  };

  return (
    <>
      <form className="test-api-form">
        <div className="form-container">
          {/* Basic Details - Left Side */}
          <div className="basic-details-section">
            <div className="form-section">
              <div className="form-group">
                <label>Name<span className="required">*</span></label>
                <input
                  type="text"
                  name="name"
                  className={getInputClassName('name')}
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Enter name"
                />
              </div>

              <div className="form-group">
                <label>AUT URL<span className="required">*</span></label>
                <input
                  type="text"
                  name="aut_url"
                  value={formData.aut_url}
                  onChange={handleInputChange}
                  placeholder="Enter AUT URL"
                />
              </div>

              <div className="form-group">
                <label>Description</label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder="Enter description"
                  rows="3"
                />
              </div>
            </div>

            {/* Multi Browser inputs */}
            <div className="form-section mt-4">
              {/* Chrome Section */}
              <div className="browser-section">
                <h5>Chrome</h5>
                <div className="checkbox-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={formData.browsers.chrome.latest}
                      onChange={() => handleBrowserChange('chrome', 'latest')}
                    />
                    Latest
                  </label>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={formData.browsers.chrome['latest-1']}
                      onChange={() => handleBrowserChange('chrome', 'latest-1')}
                    />
                    Latest - 1
                  </label>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={formData.browsers.chrome['latest-n']}
                      onChange={() => handleBrowserChange('chrome', 'latest-n')}
                    />
                    Latest - n
                  </label>
                </div>
              </div>

              {/* Firefox Section */}
              <div className="browser-section">
                <h5>Firefox</h5>
                <div className="checkbox-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={formData.browsers.firefox.latest}
                      onChange={() => handleBrowserChange('firefox', 'latest')}
                    />
                    Latest
                  </label>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={formData.browsers.firefox['latest-1']}
                      onChange={() => handleBrowserChange('firefox', 'latest-1')}
                    />
                    Latest - 1
                  </label>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={formData.browsers.firefox['latest-n']}
                      onChange={() => handleBrowserChange('firefox', 'latest-n')}
                    />
                    Latest - n
                  </label>
                </div>
              </div>

              {/* Edge Section */}
              <div className="browser-section">
                <h5>Edge</h5>
                <div className="checkbox-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={formData.browsers.edge.latest}
                      onChange={() => handleBrowserChange('edge', 'latest')}
                    />
                    Latest
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Conditional Form Fields - Right Side */}
          <div className="conditional-fields-section">
            {renderRightSideContent()}
          </div>
        </div>
      </form>

      <div className="form-button-wrapper">
        <button className="reset-button" onClick={handleReset}>
          <RxCross1 /> Reset
        </button>
        <button 
          className="test-button" 
          disabled={isGeneratingResult} 
          onClick={handleGenerate}
        >
          Test
        </button>
      </div>

      {errorMessage && (
        <p className="error-message">{errorMessage}</p>
      )}
      
      {isGeneratingResult && (
        <div className='d-flex flex-row w-100 justify-content-start align-items-center mt-4'>
          <div className='d-flex flex-row justify-content-center align-items-center'>
            <progress id="progress-bar" aria-label="Content loading…"></progress>
          </div>
          <p className='wait-text'>I am working on it, thanks for waiting (~3mins)</p>
        </div>
      )}
    </>
  );
};

export default WebappTestForm;